<template>
  <div>
    <v-alert dense prominent shaped type="success" v-model="showAlertExcel"
    >Excel generado con exito</v-alert
    >
    <v-snackbar v-model="snackbar" top="top" color="primary">
      Este pescador ya tienen una solicitud completada y a la espera de ser
      resuelta
      <v-btn dark text @click="snackbar = false">Cerrar</v-btn>
    </v-snackbar>
    <v-card class="mx-auto">
      <v-data-table
        :footer-props="{
          'items-per-page-options': [5,10,15]
        }"
        v-model="selected"
        :headers="headers"
        :items="filtrado.data"
        :search="searchFishermen"
        sort-by="name"
        class="elevation-1"
        :server-items-length="filtrado.total"
        v-on:update:options="onPaginate"
        @input="verificarPescadores"
        :show-select="singleSelect"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">

            <v-toolbar-title
            >Pescadores
              {{
                type == "ind"
                  ? "Industriales"
                  : type == "art"
                    ? "Artesanales"
                    : type == "dep"
                      ? "Deportivos"
                      : ""
              }}
            </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              v-model="searchFishermen"
              append-icon="search"
              label="Busqueda"
              single-line
              hide-details
              v-debounce:200ms="search"
            ></v-text-field>
            <v-spacer></v-spacer>
            <export-excel
              :fetch.sync="exportFishermens"
              :before-generate="startDownload"
              :before-finish="finishDownload"
              :fields="
                    type == 'art'
                      ? fields.json_fields_art
                      : type == 'ind'
                      ? fields.json_fields_ind
                      : type == 'dep'
                      ? fields.json_fields_dep
                      : {}
                  "
              :worksheet="
                    type == 'art'
                      ? 'Pescadores Artesanales'
                      : type == 'ind'
                      ? 'IPescadores ndustriales'
                      : type == 'dep'
                      ? 'Pescadores Deportivos'
                      : ''
                  "
              :name="
                    type == 'art'
                      ? 'Pescadores Artesanales'
                      : type == 'ind'
                      ? 'Pescadores Industriales'
                      : type == 'dep'
                      ? 'Pescadores Deportivos'
                      : ''
                  "
              style="ml-4 margin-bottom:10px"
            >
              <v-btn
                color="green darken-4 ml-4 white--text"
                small
                dark
                class="mb-2"
                ref="export"
                v-if="type == 'ind'
                      ? $can('exportarExcelIndustriales')
                      : type == 'art'
                      ? $can('exportarExcelArtesanales')
                      : type == 'dep'
                      ? $can('exportarExcelDeportivos')
                      : ''"
              >
                <v-icon small class="mr-2">fa fa-file-excel</v-icon>
                Exportar
              </v-btn>
              <!--v-icon>file-excel</v-icon-->
            </export-excel>
          </v-toolbar>
          <v-menu v-model="showMenu">
            <template v-slot:activator="{ on }">
              <v-btn class="ml-4" v-on="on">
                {{ name_filter_muni }}
                <v-icon>filter_list</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item style="cursor: pointer">
                <v-list-item-content @click="filterPer('muni', '', 'Municipio')">
                  <v-list-item-title>Todos</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="cursor: pointer">
                <v-list-item-content
                  @click="filterPer('muni', 'UNO', 'San Andrés')"
                >
                  <v-list-item-title>San Andrés</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="cursor: pointer">
                <v-list-item-content
                  @click="filterPer('muni', 'DOS', 'Providencia')"
                >
                  <v-list-item-title>Providencia</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu v-model="showMenu_v">
            <template v-slot:activator="{ on }">
              <v-btn class="ml-4" v-on="on">
                {{ name_filter_vigency }}
                <v-icon>filter_list</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item style="cursor: pointer">
                <v-list-item-content
                  @click="filterPer('vigency', '', 'Vigencia')"
                >
                  <v-list-item-title>Todos</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="cursor: pointer">
                <v-list-item-content
                  @click="filterPer('vigency', '1', 'Vigencia (SI)')"
                >
                  <v-list-item-title>Si</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="cursor: pointer">
                <v-list-item-content
                  @click="filterPer('vigency', '0', 'Vigencia (NO)')"
                >
                  <v-list-item-title>No</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:item.name="{ item }">
          {{ item.name |  uppercase }} {{ item.lastname |  uppercase  }}
        </template>
        <template v-slot:item.expiration_date="{ item }">
          <v-chip
            :color="permisoVigente(item.expiration_date, item.estado)"
            dark
          >{{
              permisoVigente(item.expiration_date, item.estado) == "green"
                ? "Sí"
                : "No"
            }}
          </v-chip>
        </template>
        <!-- FOTO -->
        <template v-slot:item.fisherman_photo_file="{ item }">
          <v-avatar
            v-if="
              item.fisherman_photo_file &&
              item.fisherman_photo_file.substring(0, 10) === 'fisherman-'
            "
          >
            <img
              :src="path + item.fisherman_photo_file"
              alt="John"
              @click="showModal(item)"
            />
          </v-avatar>
        </template>
      </v-data-table>
    </v-card>
    <view-fishing-card v-if="fisherman.fishingCard"></view-fishing-card>
    <v-dialog v-model="modalVisible" max-width="1000px">
      <v-card>
        <v-card-title class="text-h6 text-center">{{fullName}}</v-card-title>
        <v-card-text class="d-flex justify-center">
          <img
            :src="imageUrl"
            alt="Imagen del pescador"
            style="
          max-width: 90%;
          width: 800px;
          height: auto;
          object-fit: contain;
          display: block;
          margin: 0 auto;
        "
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-darken-4" text @click="closeModal">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { getAllRequestByFisherman, apiDomain } from "../../config";
import getFishermanFields from "../../services/exportFishermens";
import axios from "axios";
import ViewFishingCard from "../../views/ViewFishingCard";
import { findRequestFishermen, getHeader } from "../../config";
import swal from "sweetalert";
import { photoFishermanUrl, firmaUrl } from "../../config";
import baseService from "../../services/BaseService";

export default {
  props: ["type"],
  data: () => ({
    path: `${apiDomain}storage/public/fishermen/`,
    imageUrl: '',
    fullName: '',
    modalVisible: false,
    searchFishermen: "",
    dialogSee: false,
    solicitudes: [],
    fields: {},
    showMenu: false,
    showMenu_v: false,
    singleSelect: false,
    name_filter_vigency: "Vigencia",
    name_filter_muni: "Municipio",
    selected: [],
    headers: [
      { text: "Foto", sortable: false, value: "fisherman_photo_file" },
      { text: "No. de Permiso", sortable: true, value: "fishin_card_number" },
      { text: "Nombre(s)", align: "left", sortable: true, value: "name" },
      {
        text: "Número de Identificación",
        sortable: false,
        value: "identification_number",
      },
      { text: "Municipio", sortable: false, value: "lugar.nombre" },
      { text: "Vigencia", sortable: false, value: "expiration_date" }
    ],

    //VARIABLES FORM
    menu_date_expedition: false,
    menu_date_expiration: false,
    date_expedition: "",
    date_expiration: "",
    item_date_expiration: "",
    dialogPro: false,

    dialog: false,

    snackbar: false,
    filtrado: [],
    editedItem: {},
    dataItem: {},
    company: {},
    barco: {},
    lugar: {},
    tipo_autorizacion: {},
    tipo_solicitud: {},
    artes_autorizadas: [],
    type_of_company: "",
    defaultItem: {},
    department: {},
    nacionalidad: {},
    organizacion: {},
    arte_pesca: {},
    sexo: {},
    tiempo_en_actividad: {},
    puerto_desembarque: {},
    tipo_barco_artesanal: {},
    tipo_pesqueria: {},
    estado_civil: {},
    nivel_educativo: {},
    tipo_servicio_salud: {},
    tiempo_de_pesca: {},
    horario_de_pesca: {},
    excelFishermens: [],
    tipo_permiso: {},
    pageNumber: 0,
    filterLocation: "",
    filterVigency: "",
    showAlertExcel: false,
    pathFisherman: photoFishermanUrl,
    fishermanStatus: "",
    fishingCard: {},
    firmaSecretario: {}
  }),
  components: {
    ViewFishingCard,
  },
  computed: {
    ...mapGetters([
      "allFishermen",
      "allFishermenArt",
      "allFishermenInd",
      "allFishermenDep",
      "fishermenData",
    ]),
    ...mapState(["fisherman"]),
    fishermanOrg() {
      if (this.fishermanStatus === "INDEPENDENT") {
        return "NOT APPLICABLE";
      } else {
        return this.fishermanOrganization;
      }
    },
    occreType() {
      if (this.fishingCard.occre === "RESIDENTE") {
        return "RESIDENT";
      } else if (this.fishingCard.occre === "RAIZAL") {
        return "RAIZAL";
      } else {
        return "";
      }
    },
    username() {
      return JSON.parse(localStorage.getItem("authUser"));
    },
    canManagement(){
      if (this.type === 'ind') {
        return this.$can('gestionarPescadorIndustrial')
      }
      else if (this.type === 'art') {
        return this.$can('gestionarPescadorArtesanal')
      } else {
        return this.$can('gestionarPescadorDeportivo')
      }
    }
  },

  watch: {
    type: async function () {
      this.filterFishermen();
    },
  },
  created: async function () {
    await this.onPaginate({ page: 1, itemsPerPage: 10 });
    this.fields = await getFishermanFields();
    const url =
      firmaUrl +
      this.$store.state.settings.find((x) => x.nombre == "firma_secretario")
        .valor;
    const data = await this.generarBase64Blob(url);
    this.firmaSecretario = data;
  },

  methods: {
    reloadFishermen()
    {
      this.filterVigency = Number(this.singleSelect);
      this.name_filter_vigency = 'Vigencia (SI)';
      if (!this.filterVigency) {
        this.name_filter_vigency = 'Vigencia (NO)'
      }
      this.onPaginate({ page: 1, itemsPerPage: 10 });
    },
    verificarPescadores()
    {
      if(this.selected.length > 10)
      {
        let array = this.selected;
        array.pop();
        this.selected = array;

        swal({
          title: "Error!",
          text: "Solo se pueden seleccionar como maximo 10 pescadores",
          icon: "error",
        });
      }
    },
    getQueryFilter() {
      return {
        ...(this.filterLocation &&
          { location:  this.filterLocation }),
        ...(this.filterVigency && { vigency:  this.filterVigency }),
        ...(this.searchFishermen && { search :this.searchFishermen }),
      }
    },
    async filterFishermen(itemsPerPage = 10) {
      const queryString = this.getQueryFilter();
      this.filtrado = await this.getFishermen(this.type, {
        page: 1,
        itemsPerPage,
        query: queryString,
      });
    },
    async generarBase64Blob(urlFoto) {
      this.$store.commit('LOADER', true);
      //this.$store.commit("START_LOADER");
      const { data } = await baseService.get(urlFoto, { responseType: "blob" });
      this.$store.commit('LOADER', false);
      //this.$store.commit("STOP_LOADER");
      return this.blobToBase64(data);
    },
    blobToBase64(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },
    async generarBase64(urlFoto) {
      return new Promise((resolve) => {
        var base64Img = require("base64-img");
        base64Img.requestBase64(urlFoto, function (err, res, body) {
          resolve(body);
        });
      });
    },

    loadImage(item) {
      this.imageUrl = photoFishermanUrl + item.fisherman_photo_file;
      this.fullName = item.full_name;
      return this.imageUrl, this.fullName
    },
    closeModal() {
      this.modalVisible = false;
    },
    showModal(item) {
      this.loadImage(item);
      this.modalVisible = true;
    },
    async filterPer(type, value, name_filter) {
      if (type == "muni") {
        this.name_filter_muni = name_filter;
        this.filterLocation = value;
      } else {
        this.name_filter_vigency = name_filter;
        this.filterVigency = value;
        if (this.filterVigency == 0){
          this.singleSelect = false;
        }
      }
      this.filterFishermen();
    },
    permisoVigente(fecha, estado) {
      if (fecha > new Date().toISOString().substr(0, 10) && estado == 1)
        return "green";
      else return "red";
    },
    seeItem: async function (item_id) {
      this.solicitudes = await axios.get(`${getAllRequestByFisherman}/${item_id}`), {
        headers: getHeader(),
      };
      this.solicitudes = this.solicitudes.data;
      await this.$store.dispatch("getFishermen", { _id: item_id });
      this.editedItem = Object.assign({}, this.fishermenData.data);
      this.dialogSee = true;
    },
    Peticion: async function (peticion, item) {
      const verif_req = (
        await axios.get(`${findRequestFishermen}/${item.id}`, {
          headers: getHeader(),
        })
      )?.data;
      if (Object.keys(verif_req).length) {
        this.dialogPro = false;
        this.snackbar = true;
      } else {
        this.$router.push({
          path: `/request-fisherman-${this.type}-${peticion}-${item.id}-new-req`,
        });
      }
    },
    async onPaginate(pagination) {
      let { sortBy, sortDesc } = pagination;
      const queryString = this.getQueryFilter();
      this.filtrado = await this.getFishermen(this.type, {
        ...pagination,
        ...((this.filterVigency || this.filterLocation || sortBy?.[0]) && {
          query: {
            ...queryString,
            ...sortBy?.[0] && {
              [`${sortBy[0]}`]: sortDesc[0]
            }},
        }),
      });
    },
    async getFishermen(type, { page, itemsPerPage, query = null }) {
      const getType = (key) =>
        ({
          art: "UNO",
          ind: "TRES",
          dep: "DOS",
        }[key]);
      await this.$store.dispatch("listFishermen",{
        type:  getType(type),
        params: {
          page,
          itemsPerPage: itemsPerPage < 0 ? this.filtrado.total : itemsPerPage,
          ...query,
        }
      });
      return this.allFishermen.data;
    },
    async exportFishermens() {
      const queryString = this.getQueryFilter();
      return (
        await this.getFishermen(this.type, {
          page: 1,
          itemsPerPage: this.filtrado.total,
          query: queryString,
        })
      )?.data;
    },
    startDownload() {
      this.$store.commit("LOADER", true);
     // this.$store.commit("START_LOADER");
    },
    finishDownload() {
      this.$store.commit("LOADER", false);
      //this.$store.commit("STOP_LOADER");
      this.showAlertExcel = true;
      setTimeout(() => {
        this.showAlertExcel = false;
      }, 1000);
    },
    search() {
      this.$store.commit("LOADER", undefined);
      //this.$store.commit("START_LOADER");
      this.filterFishermen();
    },
  },
};
</script>

<style lang="scss" scoped></style>