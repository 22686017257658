<template>
  <div>
    <v-card-title class="headline"
      >Gráficos de Pescadores
      {{
        type == "ind"
          ? "Industriales"
          : type == "art"
          ? "Artesanales"
          : type == "dep"
          ? "Deportivos"
          : ""
      }}</v-card-title
    >
    <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="6" v-for="(chart, i) in charts" :key="`chart_${i}`">
                <highcharts :options="chart"></highcharts>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    
    <v-card>
      <fishermans-table :type="type"></fishermans-table>
    </v-card>
  </div>
</template>

<script>
import { photoFishermanUrl } from "../../config";
import { mapGetters } from "vuex";
import Highcharts3D from "highcharts/highcharts-3d";
import Highcharts from "highcharts";
import getFishermanFields from "../../services/exportFishermens";
import {FishermanStats} from "../../services/StatsService";
import FishermansTable from "./FishermansTable";
import { consultar } from "../../services/variablesconcepto";

Highcharts3D(Highcharts);
var options = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  title: {
    text: "Total Pescadores por lugar",
  },
  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  series: [
    {
      colorByPoint: true,
      data: [],
      group: [],
    },
  ],
};

var options1 = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  title: {
    text: "Total Pescadores por tipo asociación",
  },
  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  series: [
    {
      colorByPoint: true,
      data: [],
      group: [],
    },
  ],
};
var options2 = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  title: {
    text: "Pescadores por tipo de pesquería",
  },
  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  series: [
    {
      colorByPoint: true,
      data: [],
    },
  ],
};
var options3 = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  title: {
    text: "Pescadores por arte de pesca",
  },
  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  series: [
    {
      colorByPoint: true,
      data: [],
      group: [],
    },
  ],
};
var options4 = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  title: {
    text: "pescadores / asociación",
  },
  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  series: [
    {
      colorByPoint: true,
      data: [],
      group: [],
    },
  ],
};
export default {
  components: { FishermansTable },
  props: ["type"],
  data: () => ({
    fields: {},
    head_filter: "",
    showMenu: false,
    options: options,
    path: photoFishermanUrl,
    options1: options1,
    options2: options2,
    options3: options3,
    options4: options4,
    totalPescadores: "",
    search: "",
    selected: [],
    singleSelect: "",
    items_select_types_of_fishing: [
      { nombre: "1. Langosta", codigo: "UNO" },
      { nombre: "2. Pesca blanca", codigo: "DOS" },
      { nombre: "3. Caracol", codigo: "TRES" },
      { nombre: "4. Multiespecífica", codigo: "CUATRO" },
      { nombre: "5. Otro", codigo: "CINCO" },
    ],
    headers: [
      { text: "Foto", sortable: false, value: "fisherman_photo_file" },
      { text: "No. de Registro", value: "fishin_card_number" },
      { text: "Nombre(s)", align: "left", sortable: false, value: "name" },
      { text: "Numero Identificacion", value: "identification_number" },
      { text: "Municipio", value: "lugar.nombre" },
      { text: "Permiso vigente", value: "expiration_date" },
    ],
    listFisherman: [],

    alloptions: [options, options1, options3, options4],
    charts:[]
  }),

  computed: {
    ...mapGetters([
      // map this.productItems to this.$store.getters.productItems
      "allFishermen",
    ]),
  },
  watch: {
    type: async function (newV) {
      await this.$store.dispatch("listFishermen", {
        type:
          newV == "art"
            ? "UNO"
            : newV == "ind"
            ? "TRES"
            : newV == "dep"
            ? "DOS"
            : "invalid",
      });
      this.listFisherman = this.allFishermen.data;

      this.setData();
    },
  },
  methods: {
    async plotStats() {

      const type=this.type == "art"
          ? "UNO"
          : this.type == "ind"
          ? "TRES"
          : this.type == "dep"
          ? "DOS"
          : "invalid";

      this.charts = [];

      const graficas = {
        lugar_ART: {
          name: "lugar",
          type: "ART",
          title: "Total pescadores por lugar",
        },
        fishing_arts_ART: {
          name: "fishing_arts",
          type: "ART",
          title: "Total de pescadores por arte de Pesca",
        },
        fisherman_company_ART: {
          name: "fisherman_company",
          type: "ART",
          title: "Pescadores por asociacion",
        },
        lugar_IND: {
          name: "lugar",
          type: "IND",
          title: "Total pescadores por lugar",
        },
        fishing_arts_IND: {
          name: "fishing_arts",
          type: "IND",
          title: "Total de pescadores por arte de Pesca",
        },
        types_of_fishery_IND: {
          name: "types_of_fishery",
          type: "IND",
          title: "Total de pescadores por tipo de pesqueria",
        },
        types_of_fishery_ART: {
          name: "types_of_fishery",
          type: "ART",
          title: "Total de pescadores por tipo de pesqueria",
        },
        lugar_DEP: {
          name: "lugar",
          type: "DEP",
          title: "Total pescadores por lugar",
        },
        fishing_arts_DEP: {
          name: "fishing_arts",
          type: "DEP",
          title: "Total de pescadores por arte de Pesca",
        },
      };

      const mapToSerieObject = (x) => {
        let _key = Object.keys(x)[0];
        return {
          key: _key,
          name: `${_key}:${x[_key]}`,
          y: x[_key],
          sliced: true,
        };
      };

      let stats =(await FishermanStats(type))?.data;

      for (const item in stats) {
        let type = this.type.toUpperCase();
        let index = `${item}_${type}`;
        let chart = graficas[index];
        let data = [];
        
          if(chart!== undefined){
            data = stats[chart?.name].map(mapToSerieObject);
            this.options.title.text = chart.title;
            this.options.series[0].data = data || [];

            this.charts.push(JSON.parse(JSON.stringify(this.options)));
          }
         
        
      }
    },
    filterPer(value) {
      this.showMenu = false;
      this.listFisherman = this.allFishermen.data;

      let filtrado = [];
      this.head_filter = "";

      this.listFisherman.forEach((x) => {
        if (value != null) {
          const type_fishery = x.type_fishery.find((y) => y.codigo == value);

          this.head_filter =
            "Tipos de Pesquería" +
            " | Filtro: " +
            this.items_select_types_of_fishing.find((el) => el.codigo == value)
              .nombre;

          if (type_fishery != undefined) {
            filtrado.push(x);
          }
        } else {
          this.head_filter = "Tipos de Pesquería" + " | Filtro: Desconocido";

          filtrado.push(x);
        }
      });
      this.listFisherman = filtrado;
    },
    groupBy: function (list, keyGetter) {
      const map = new Map();
      list.map((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
      });
      return map;
    },
    permisoVigente(fecha, estado) {
      if (fecha > new Date().toISOString().substr(0, 10) && estado == 1)
        return "green";
      else return "red";
    },
    showList(obj) {
      this.listFisherman = [];
      this.listFisherman = obj;
    },
    getTotalpescadoresProYSan() {
      const PescadoresGrouped = this.groupBy(
        this.listFisherman,
        (fisher) => fisher.location
      );

      this.options.series[0].group = PescadoresGrouped;
      options.series[0].data = [];
      PescadoresGrouped.forEach((element, key) => {
        const dataArray = {
          key: key,
          name:
            element[0].lugar != null
              ? element[0].lugar.nombre + ":" + element.length
              : "Desconocido" + ":" + element.length,
          y: element.length,
          sliced: true,
        };
        const arrayGraphic = options.series[0].data;
        arrayGraphic.push(dataArray);
      });
    },
    // Pescadores asociados vs pescadores independientes
    getPescadoresAsovsInd() {
      const PescadoresGrouped = this.groupBy(
        this.listFisherman,
        (fisher) => fisher.organization_cooperative
      );
      this.options1.series[0].group = PescadoresGrouped;
      options1.series[0].data = [];
      PescadoresGrouped.forEach((element, key) => {
        let asociados = "";
        if (
          element[0].organization_cooperative == 0 ||
          element[0].organization_cooperative == "NO" ||
          element[0].organization_cooperative == "FALSE"
        ) {
          asociados = "Independientes";
        }
        if (
          element[0].organization_cooperative == 1 ||
          element[0].organization_cooperative == "SI" ||
          element[0].organization_cooperative == "TRUE"
        ) {
          asociados = "Asociados";
        }
        if (element[0].organization_cooperative == null) {
          asociados = "Desconocidos";
        }
        const dataArray = {
          key: key,
          name: asociados + ":" + element.length,
          y: element.length,
          sliced: true,
        };
        const arrayGraphic = options1.series[0].data;
        arrayGraphic.push(dataArray);
      });
    },
    // Pescadores por tipo de pesquería
    getFishermanTypeFishery() {
      let Pescadores = [];
      this.listFisherman.forEach((x) => {
        if (x.type_fishery.length > 0) {
          x.type_fishery.forEach((el) => {
            Pescadores.push({ nombre: el.nombre, codigo: el.codigo });
          });
        } else {
          Pescadores.push({ nombre: null, codigo: null });
        }
      });

      const PescadoresGrouped = this.groupBy(Pescadores, (el) => el.codigo);

      // this.options2.series[0].group = PescadoresGrouped;
      options2.series[0].data = [];
      PescadoresGrouped.forEach((element, key) => {
        const dataArray = {
          key: key,
          name:
            element[0].codigo !== null
              ? element[0].nombre + ":" + element.length
              : "Desconocido" + ":" + element.length,
          y: element.length,
          sliced: true,
        };

        const arrayGraphic = options2.series[0].data;

        arrayGraphic.push(dataArray);
      });
    },
    // Pescadores por arte de pesca
    getFishermanArts() {
      const PescadoresGrouped = this.groupBy(this.listFisherman, (fisher) =>
        fisher.arte_pesca != null ? fisher.arte_pesca.nombre : null
      );

      this.options3.series[0].group = PescadoresGrouped;
      options3.series[0].data = [];
      PescadoresGrouped.forEach((element, key) => {
        //

        const dataArray = {
          key: key,
          name:
            element[0].arte_pesca !== null
              ? element[0].arte_pesca.nombre + ":" + element.length
              : "Desconocido" + " : " + element.length,
          y: element.length,
          sliced: true,
        };
        const arrayGraphic = options3.series[0].data;
        arrayGraphic.push(dataArray);
      });
    },
    // pescadores / asociación
    getFishermanOrganizationName() {
      const PescadoresGrouped = this.groupBy(
        this.listFisherman,
        (fisher) => fisher.organization_name
      );

      this.options4.series[0].group = PescadoresGrouped;
      options4.series[0].data = [];

      PescadoresGrouped.forEach((element, key) => {
        const dataArray = {
          key: key,
          name:
            element[0].organization !== null
              ? element[0].organization.abbr_name + ":" + element.length
              : "Desconocido" + " : " + element.length,
          y: element.length,
          sliced: true,
        };
        const arrayGraphic = options4.series[0].data;
        arrayGraphic.push(dataArray);
      });
    },
    reset() {
      this.listFisherman = [];
      this.listFisherman = this.allFishermen.data;
      this.head_filter = "";
    },
    /*setData: async function () {
      if (this.type == "ind" || this.type == "dep") {
        this.alloptions.splice(1, 1);
        this.alloptions.splice(2, 1);
        
      } else {
        this.getPescadoresAsovsInd();
        this.getFishermanOrganizationName();
      }

      this.getTotalpescadoresProYSan();
      this.getFishermanTypeFishery();
      this.getFishermanArts();
    },*/
  },

  created: async function () {
    this.fields = await getFishermanFields();
    await this.$store.dispatch("listFishermen", {
      type:
        this.type == "art"
          ? "UNO"
          : this.type == "ind"
          ? "TRES"
          : this.type == "dep"
          ? "DOS"
          : "invalid",
    });
    this.listFisherman = this.allFishermen.data.data;
    //this.setData();
    this.plotStats();

    const concepto = await consultar.fetch();

    this.items_select_types_of_fishing = concepto.items_select_types_of_fishing;
  },

  
};
</script>
