import  consultarVariables from "../utils/itemsFisherman";

export default async function getFishermanFields() {
  const variables = await consultarVariables();
  let fields = {
  json_fields_art: {
    "Numero de Carnet de Pesca": "fishin_card_number",
    Nombres: "name",
    Apellidos: "lastname",
    "Tipo de Identificación": "id_type",
    "No. de Identificación": "identification_number",
    "Clasificación certificación OCCRE": "occre",
    "No. OCCRE": "no_occre",
    Nacionalidad: "nacionalidad.nombre",
    "E-mail": "email",
    Dirección: "address",
    "Teléfono": "phone",
    "Certificado Por": {
      callback(item) {
        if (
          item.certifying_organization?.abbr_name === 'DECLARACIÓN JURAMENTADA' ||
          item.certification_issued_by_state
        ) {
          return item.certification_issued_by_state || 'DECLARACIÓN JURAMENTADA';
        }
        return item.certifying_organization?.abbr_name || '';
      },
    },
    "Permiso Vigente": {
      callback(value) {
        if (value.expiration_date != null) {
          if (
            value.expiration_date > new Date().toISOString().substr(0, 10) &&
            value.estado == 1
          )
            return "Si";
          else return "No";
        } else {
          return "Sin permiso";
        }
      },
    },
    "Fecha de Expedición": "expedition_date",
    "Fecha de Vencimiento": "expiration_date",
    "Organización / Independiente": "organization.abbr_name",
    "Zona de desembarco": "puerto_desembarque.nombre",
    "Zona frecuente de pesca": "frequent_fishing_area",
    "Tipo de embarcación artesanal": {
     field: "type_of_artesanal_boat",
     callback: (value) => {
       if(value) {
        return variables.items_select_type_of_boat.find(
          x => x.abbr == value
        )?.state || '';
       }
     }
    },
    "Tipo de pesquería": {
      field: "type_fishery",
      callback: (value) => {
        var types = "";
        let i = 1;
        if (value != null) {
          value.map((x) => {
            types += `${x.pivot.description}`;
            if (i < value.length) {
              types += `, `;
            }
            i++;
          });
        }
        return `${types}`;
      },
    },
    "Artes de pesca": {
      field: "fishing_arts",
      callback(value) {
        var arts = "";
        let i = 1;
        if (value != null) {
          value.map((x) => {
            arts += `${x.pivot.description}`;
            if (i < value.length) {
              arts += `, `;
            }
            i++;
          });
        }
        return `${arts}`;
      },
    },
    "Reporta Embarcación": "report_boat",
    Embarcaciones: {
      field: "boats",
      callback(value) {
        if (value != null) {
          var boats = "";
          let i = 1;

          value.map((x) => {
            boats += `[ ${x.boat_name} (${
              x.registration_number
            }) | Propietario:  ${x.pivot.propietario == 1 ? "SI" : "NO"} ]`;
            if (i < value.length) {
              boats += `, `;
            }
            i++;
          });

          return `${boats}`;
        }
      },
    },
    "Fecha de Nacimiento": "birth_date",
    Edad: "age",
    Sexo: {
      field: "gender",
      callback(value) {
        if (value != null) {
          let campo = variables.items_select_gender.find(
            (x) => x.abbr == value
          );
          if (campo != undefined) {
            return variables.items_select_gender.find((x) => x.abbr == value)
              .state;
          }
        }
      },
    },
    "Estado Civil": {
      field: "marital_status",
      callback(value) {
        if (value != null) {
          let campo = variables.items_select_marital_status.find(
            (x) => x.abbr == value
          );
          if (campo != undefined) {
            return variables.items_select_marital_status.find(
              (x) => x.abbr == value
            ).state;
          }
        }
      },
    },
    "Lee y Escribe": "read_and_write",
    "Nivel Educativo": {
      field: "education_level",
      callback(value) {
        if (value != null) {
          let campo = variables.items_select_education_level.find(
            (x) => x.abbr == value
          );
          if (campo != undefined) {
            return variables.items_select_education_level.find(
              (x) => x.abbr == value
            ).state;
          }
        }
      },
    },
    "Posee Casa Propia": "own_house",
    "Posee Servicio de Salud": "health_service",
    "Tipo de Servicio de Salud": {
      field: "type_health_service",
      callback(value) {
        if (value != null) {
          let campo = variables.items_select_type_health_service.find(
            (x) => x.abbr == value
          );
          if (campo != undefined) {
            return variables.items_select_type_health_service.find(
              (x) => x.abbr == value
            ).state;
          }
        }
      },
    },
    "Tiempo de Pesca": {
      field: "fishing_time",
      callback(value) {
        if (value != null) {
          let campo = variables.items_select_fishing_time.find(
            (x) => x.abbr == value
          );
          if (campo != undefined) {
            return variables.items_select_fishing_time.find(
              (x) => x.abbr == value
            ).state;
          }
        }
      },
    },
    "Dedicación": {
      field: "time_in_activity",
      callback(value) {
        if (value != null) {
          let campo = variables.items_select_time_in_activity.find(
            (x) => x.abbr == value
          );
          if (campo != undefined) {
            return variables.items_select_time_in_activity.find(
              (x) => x.abbr == value
            ).state;
          }
        }
      },
    },
    "Horario de Pesca": {
      field: "fishing_schedule",
      callback(value) {
        if (value != null) {
          let campo = variables.items_select_fishing_schedule.find(
            (x) => x.abbr == value
          );
          if (campo != undefined) {
            return variables.items_select_fishing_schedule.find(
              (x) => x.abbr == value
            ).state;
          }
        }
      },
    },
    "Asociado/Independiente": {
      field: "organization_cooperative",
      callback(value)
      {
        return value == 'NO' ? 'INDEPENDIENTE' : 'ASOCIADO'
        }
    }
  },
  json_fields_ind: {
    "Numero de Permiso de Pesca": "fishin_card_number",
    Nombres: "name",
    Apellidos: "lastname",
    "Tipo de Identificación": "id_type",
    "No. de Identificación": "identification_number",
    "Clasificación certificación OCCRE": "occre",
    "No. OCCRE": "no_occre",
    Nacionalidad: "nacionalidad.nombre",
    "E-mail": "email",
    Dirección: "address",
    "Teléfono": "phone",
    "Certificado Por": "certification_issued_by_state",
    "Permiso Vigente": {
      callback(value) {
        if (value.expiration_date != null) {
          if (
            value.expiration_date > new Date().toISOString().substr(0, 10) &&
            value.estado == 1
          ) {
            return "Si";
          } else {
            return "No";
          }
        } else {
          return "Sin permiso";
        }
      },
    },
    "Fecha de Expedición": "expedition_date",
    "Fecha de Vencimiento": "expiration_date",
    "Nombre de la organización": "organization.abbr_name",
    "Tipo de pesqueria": {
      field: "types_of_fishery",
      callback(value) {
        if (value != null) {
          let campo = variables.items_select_types_fishing.find(
            (x) => x.abbr == value
          );
          if (campo != undefined) {
            return variables.items_select_types_fishing.find(
              (x) => x.abbr == value
            ).state;
          }
        }
      },
    },
    "Nombre de la Embarcación": "barco.boat_name",
    "N° de Matricula de Embarcación": "barco.registration_number",
    "Fecha de Nacimiento": "birth_date",
    Edad: "age",
    Sexo: {
      field: "gender",
      callback(value) {
        if (value != null) {
          let campo = variables.items_select_gender.find(
            (x) => x.abbr == value
          );
          if (campo != undefined) {
            return variables.items_select_gender.find((x) => x.abbr == value)
              .state;
          }
        }
      },
    },
    "Asociado/Independiente": {
      field: "organization_cooperative",
      callback(value)
      {
        return value == 'NO' ? 'INDEPENDIENTE' : 'ASOCIADO'
      }
    }
  },
  json_fields_dep: {
    "Numero de Carnet de Pesca": "fishin_card_number",
    Nombres: "name",
    Apellidos: "lastname",
    "Tipo de Identificación": "id_type",
    "No. de Identificación": "identification_number",
    Nacionalidad: "nacionalidad.nombre",
    "E-mail": "email",
    Dirección: "address",
    "Teléfono": "phone",
    "Certificado Por": "certification_issued_by_state",
    "Permiso Vigente": {
      callback(value) {
        if (value.expiration_date != null) {
          if (
            value.expiration_date > new Date().toISOString().substr(0, 10) &&
            value.estado == 1
          )
            return "Si";
          else return "No";
        }
      },
    },
    "Fecha de Expedición": "expedition_date",
    "Fecha de Vencimiento": "expiration_date",
    "Nombre de la organización": "organization.abbr_name",
    "Zona de desembarco": "puerto_desembarque.nombre",
    "Artes de pesca": {
      field: "fishing_arts",
      callback(value) {
        var arts = "";
        let i = 1;
        if (value != null) {
          value.map((x) => {
            arts += `${x.pivot.description}`;
            if (i < value.length) {
              arts += `, `;
            }
            i++;
          });
        }
        return `${arts}`;
      },
    },
    "Fecha de Nacimiento": "birth_date",
    Edad: "age",
    Sexo: {
      field: "gender",
      callback(value) {
        if (value != null) {
          let campo = variables.items_select_gender.find(
            (x) => x.abbr == value
          );
          if (campo != undefined) {
            return variables.items_select_gender.find((x) => x.abbr == value)
              .state;
          }
        }
      },
    },
    "Asociado/Independiente": {
      field: "organization_cooperative",
      callback(value)
      {
        if(value == null)
          return 'Sin dato';

        return value == 'NO' ? 'INDEPENDIENTE' : 'ASOCIADO';
      }
    }
  }
 }
 return fields;
}
