const consultarVariables = async () => {
  const variables = {
    items_select_type_of_request: [
      { state: "Seleccionar", abbr: "CERO" },
      { state: "1. Nuevo", abbr: "UNO" },
      { state: "2. Prorroga", abbr: "DOS" },
      { state: "3. Duplicado y/o Modificación", abbr: "TRES" },
      { state: "4. Cancelación del Permiso", abbr: "CUATRO" },
    ],
    items_select_type_of_card: [
      { state: "Seleccionar", abbr: "CERO" },
      { state: "1. Pesca Comercial Artesanal", abbr: "UNO" },
      { state: "2. Pesca deportiva", abbr: "DOS" },
      { state: "3. Certificado de Pesca Industrial", abbr: "TRES" },
    ],
    items_select_location: [
      { state: "1.SAN ANDRES", abbr: "UNO" },
      { state: "2. PROVIDENCIA", abbr: "DOS" },
    ],

    items_select_department: [
      { state: "Seleccione", abbr: "CERO" },
      {
        state:
          "1. DEPARTAMENTO ARCHIPIELAGO DE SAN ANDRES, PROVIDENCIA Y SANTA CATALINA",
        abbr: "UNO",
      },
    ],
    items_select_type_person: [
      { state: "Natural", abbr: "UNO" },
      { state: "Jurídica", abbr: "DOS" },
    ],
    items_select_fishing_schedule: [
      { state: "Seleccione", abbr: "CERO" },
      { state: "1. Mañana", abbr: "UNO" },
      { state: "2. Tarde", abbr: "DOS" },
      { state: "3. Noche", abbr: "TRES" },
      { state: "4. Todo el dia", abbr: "CUATRO" },
    ],
    items_select_time_in_activity: [
      { state: "Seleccione", abbr: "CERO" },
      { state: "1. Completo", abbr: "UNO" },
      { state: "2. Medio", abbr: "DOS" },
      { state: "3. Ocasional", abbr: "TRES" },
      { state: "4. Todo el día", abbr: "CUATRO" },
    ],
    items_select_fishing_time: [
      { state: "Seleccione", abbr: "CERO" },
      { state: "1. 1-2 AÑOS", abbr: "UNO" },
      { state: "2. 3- 5 AÑOS", abbr: "DOS" },
      { state: "3. MAS DE 5 AÑOS", abbr: "TRES" },
    ],
    items_select_type_health_service: [
      { state: "Seleccione", abbr: "CERO" },
      { state: "1. Sisben", abbr: "UNO" },
      { state: "2. EPS publica", abbr: "DOS" },
      { state: "3. EPS privada", abbr: "TRES" },
    ],
    items_select_education_level: [
      { state: "1. Ninguno", abbr: "UNO" },
      { state: "2. Primaria", abbr: "DOS" },
      { state: "3. Secundaria", abbr: "TRES" },
      { state: "4. Tecnico", abbr: "CUATRO" },
      { state: "5. Universitario", abbr: "CINCO" },
      { state: "6. Otro, Cual?", abbr: "SEIS" },
    ],
    items_select_marital_status: [
      { state: "1. Soltero (a)", abbr: "UNO" },
      { state: "2. Casado (a)", abbr: "DOS" },
      { state: "3. Viudo (a)", abbr: "TRES" },
      { state: "4. Unión libre", abbr: "CUATRO" },
    ],
    items_select_gender: [
      { state: "Seleccione", abbr: "CERO" },
      { state: "Masculino", abbr: "MALE" },
      { state: "Femenino", abbr: "FEMALE" },
    ],
    items_select_type_of_fishing: [
      { state: "1. Langosta", abbr: "UNO" },
      { state: "2. Pesca blanca", abbr: "DOS" },
      { state: "3. Caracol", abbr: "TRES" },
      { state: "4. Multiespecífica", abbr: "CUATRO" },
    ],
    items_select_type_of_boat: [
      { state: "1. king fiber", abbr: "UNO" },
      { state: "2. pangas", abbr: "DOS" },
      { state: "3. Go fast", abbr: "TRES" },
      { state: "4. lancha (hasta 16 m eslora)", abbr: "CUATRO" },
    ],
    landing_zone: "",
    items_select_landing_zone: [
    ],
    items_select_nationality: [
      { state: "Afghanistan", abbr: " AFG" },
      { state: "Albania", abbr: " ALB" },
      { state: "Algeria", abbr: " DZA" },
      { state: "American Samoa", abbr: " ASM" },
      { state: "Andorra", abbr: " AND" },
      { state: "Angola", abbr: " AGO" },
      { state: "Anguilla", abbr: " AIA" },
      { state: "Antarctica", abbr: " ATA" },
      { state: "Antigua and Barbuda", abbr: " ATG" },
      { state: "Argentina", abbr: " ARG" },
      { state: "Armenia", abbr: " ARM" },
      { state: "Aruba", abbr: " ABW" },
      { state: "Australia", abbr: " AUS" },
      { state: "Austria", abbr: " AUT" },
      { state: "Azerbaijan", abbr: " AZE" },
      { state: "Bahamas", abbr: " BHS" },
      { state: "Bahrain", abbr: " BHR" },
      { state: "Bangladesh", abbr: " BGD" },
      { state: "Barbados", abbr: " BRB" },
      { state: "Belarus", abbr: " BLR" },
      { state: "Belgium", abbr: " BEL" },
      { state: "Belize", abbr: " BLZ" },
      { state: "Benin", abbr: " BEN" },
      { state: "Bermuda", abbr: " BMU" },
      { state: "Bhutan", abbr: " BTN" },
      { state: "Bolivia", abbr: " BO" },
      { state: "Bolivia", abbr: " BOL" },
      { state: "Bosnia and Herzegovina", abbr: " BIH" },
      { state: "Botswana", abbr: " BWA" },
      { state: "Bouvet Island", abbr: " BVT" },
      { state: "Brazil", abbr: " BRA" },
      { state: "British Indian Ocean Territory", abbr: " IOT" },
      { state: "Brunei Darussalam", abbr: " BRN" },
      { state: "Brunei", abbr: " BRN" },
      { state: "Bulgaria", abbr: " BGR" },
      { state: "Burkina Faso", abbr: " BFA" },
      { state: "Burundi", abbr: " BDI" },
      { state: "Cambodia", abbr: " KHM" },
      { state: "Cameroon", abbr: " CMR" },
      { state: "Canada", abbr: " CAN" },
      { state: "Cape Verde", abbr: " CPV" },
      { state: "Cayman Islands", abbr: " CYM" },
      { state: "Central African Republic", abbr: " CAF" },
      { state: "Chad", abbr: " TCD" },
      { state: "Chile", abbr: " CHL" },
      { state: "China", abbr: " CHN" },
      { state: "Christmas Island", abbr: " CXR" },
      { state: "Cocos (Keeling) Islands", abbr: " CCK" },
      { state: "Colombia", abbr: "COL" },
      { state: "Comoros", abbr: " COM" },
      { state: "Congo", abbr: " COG" },
      { state: "Congo", abbr: " CD" },
      { state: "Cook Islands", abbr: " COK" },
      { state: "Costa Rica", abbr: " CRI" },
      { state: "CÃ´te d'Ivoire", abbr: " CIV" },
      { state: "Ivory Coast", abbr: " CIV" },
      { state: "Croatia", abbr: " HRV" },
      { state: "Cuba", abbr: " CUB" },
      { state: "Cyprus", abbr: " CYP" },
      { state: "Czech Republic", abbr: " CZE" },
      { state: "Denmark", abbr: " DNK" },
      { state: "Djibouti", abbr: " DJI" },
      { state: "Dominica", abbr: " DMA" },
      { state: "Dominican Republic", abbr: " DOM" },
      { state: "Ecuador", abbr: " ECU" },
      { state: "Egypt", abbr: " EGY" },
      { state: "El Salvador", abbr: " SLV" },
      { state: "Equatorial Guinea", abbr: " GNQ" },
      { state: "Eritrea", abbr: " ERI" },
      { state: "Estonia", abbr: " EST" },
      { state: "Ethiopia", abbr: " ETH" },
      { state: "Falkland Islands (Malvinas)", abbr: " FLK" },
      { state: "Faroe Islands", abbr: " FRO" },
      { state: "Fiji", abbr: " FJI" },
      { state: "Finland", abbr: " FIN" },
      { state: "France", abbr: " FRA" },
      { state: "French Guiana", abbr: " GUF" },
      { state: "French Polynesia", abbr: " PYF" },
      { state: "French Southern Territories", abbr: " ATF" },
      { state: "Gabon", abbr: " GAB" },
      { state: "Gambia", abbr: " GMB" },
      { state: "Georgia", abbr: " GEO" },
      { state: "Germany", abbr: " DEU" },
      { state: "Ghana", abbr: " GHA" },
      { state: "Gibraltar", abbr: " GIB" },
      { state: "Greece", abbr: " GRC" },
      { state: "Greenland", abbr: " GRL" },
      { state: "Grenada", abbr: " GRD" },
      { state: "Guadeloupe", abbr: " GLP" },
      { state: "Guam", abbr: " GUM" },
      { state: "Guatemala", abbr: " GTM" },
      { state: "Guernsey", abbr: " GGY" },
      { state: "Guinea", abbr: " GIN" },
      { state: "Guinea-Bissau", abbr: " GNB" },
      { state: "Guyana", abbr: " GUY" },
      { state: "Haiti", abbr: " HTI" },
      { state: "Heard Island and McDonald Islands", abbr: " HMD" },
      { state: "Holy See (Vatican City State)", abbr: " VAT" },
      { state: "Honduras", abbr: " HND" },
      { state: "Hong Kong", abbr: " HKG" },
      { state: "Hungary", abbr: " HUN" },
      { state: "Iceland", abbr: " ISL" },
      { state: "India", abbr: " IND" },
      { state: "Indonesia", abbr: " IDN" },
      { state: "Iran", abbr: " IR" },
      { state: "Iraq", abbr: " IRQ" },
      { state: "Ireland", abbr: " IRL" },
      { state: "Isle of Man", abbr: " IMN" },
      { state: "Israel", abbr: " ISR" },
      { state: "Italy", abbr: " ITA" },
      { state: "Jamaica", abbr: " JAM" },
      { state: "Japan", abbr: " JPN" },
      { state: "Jersey", abbr: " JEY" },
      { state: "Jordan", abbr: " JOR" },
      { state: "Kazakhstan", abbr: " KAZ" },
      { state: "Kenya", abbr: " KEN" },
      { state: "Kiribati", abbr: " KIR" },
      { state: "Korea", abbr: " KP" },
      { state: "Korea", abbr: " KR" },
      { state: "South Korea", abbr: " KOR" },
      { state: "Kuwait", abbr: " KWT" },
      { state: "Kyrgyzstan", abbr: " KGZ" },
      { state: "Lao People's Democratic Republic", abbr: " LAO" },
      { state: "Latvia", abbr: " LVA" },
      { state: "Lebanon", abbr: " LBN" },
      { state: "Lesotho", abbr: " LSO" },
      { state: "Liberia", abbr: " LBR" },
      { state: "Libyan Arab Jamahiriya", abbr: " LBY" },
      { state: "Libya", abbr: " LBY" },
      { state: "Liechtenstein", abbr: " LIE" },
      { state: "Lithuania", abbr: " LTU" },
      { state: "Luxembourg", abbr: " LUX" },
      { state: "Macao", abbr: " MAC" },
      { state: "Macedonia", abbr: " MK" },
      { state: "Madagascar", abbr: " MDG" },
      { state: "Malawi", abbr: " MWI" },
      { state: "Malaysia", abbr: " MYS" },
      { state: "Maldives", abbr: " MDV" },
      { state: "Mali", abbr: " MLI" },
      { state: "Malta", abbr: " MLT" },
      { state: "Marshall Islands", abbr: " MHL" },
      { state: "Martinique", abbr: " MTQ" },
      { state: "Mauritania", abbr: " MRT" },
      { state: "Mauritius", abbr: " MUS" },
      { state: "Mayotte", abbr: " MYT" },
      { state: "Mexico", abbr: " MEX" },
      { state: "Micronesia", abbr: " FM" },
      { state: "Moldova", abbr: " MD" },
      { state: "Monaco", abbr: " MCO" },
      { state: "Mongolia", abbr: " MNG" },
      { state: "Montenegro", abbr: " MNE" },
      { state: "Montserrat", abbr: " MSR" },
      { state: "Morocco", abbr: " MAR" },
      { state: "Mozambique", abbr: " MOZ" },
      { state: "Myanmar", abbr: " MMR" },
      { state: "Burma", abbr: " MMR" },
      { state: "Namibia", abbr: " NAM" },
      { state: "Nauru", abbr: " NRU" },
      { state: "Nepal", abbr: " NPL" },
      { state: "Netherlands", abbr: " NLD" },
      { state: "Netherlands Antilles", abbr: " ANT" },
      { state: "New Caledonia", abbr: " NCL" },
      { state: "New Zealand", abbr: " NZL" },
      { state: "Nicaragua", abbr: " NIC" },
      { state: "Niger", abbr: " NER" },
      { state: "Nigeria", abbr: " NGA" },
      { state: "Niue", abbr: " NIU" },
      { state: "Norfolk Island", abbr: " NFK" },
      { state: "Northern Mariana Islands", abbr: " MNP" },
      { state: "Norway", abbr: " NOR" },
      { state: "Oman", abbr: " OMN" },
      { state: "Pakistan", abbr: " PAK" },
      { state: "Palau", abbr: " PLW" },
      { state: "Palestinian Territory", abbr: " PS" },
      { state: "Panama", abbr: " PAN" },
      { state: "Papua New Guinea", abbr: " PNG" },
      { state: "Paraguay", abbr: " PRY" },
      { state: "Peru", abbr: " PER" },
      { state: "Philippines", abbr: " PHL" },
      { state: "Pitcairn", abbr: " PCN" },
      { state: "Poland", abbr: " POL" },
      { state: "Portugal", abbr: " PRT" },
      { state: "Puerto Rico", abbr: " PRI" },
      { state: "Qatar", abbr: " QAT" },
      { state: "RÃ©union", abbr: " REU" },
      { state: "Romania", abbr: " ROU" },
      { state: "Russian Federation", abbr: " RUS" },
      { state: "Russia", abbr: " RUS" },
      { state: "Rwanda", abbr: " RWA" },
      { state: "Saint Helena", abbr: " SH" },
      { state: "Saint Kitts and Nevis", abbr: " KNA" },
      { state: "Saint Lucia", abbr: " LCA" },
      { state: "Saint Pierre and Miquelon", abbr: " SPM" },
      { state: "Saint Vincent and the Grenadines", abbr: " VCT" },
      { state: "Saint Vincent & the Grenadines", abbr: " VCT" },
      { state: "St. Vincent and the Grenadines", abbr: " VCT" },
      { state: "Samoa", abbr: " WSM" },
      { state: "San Marino", abbr: " SMR" },
      { state: "Sao Tome and Principe", abbr: " STP" },
      { state: "Saudi Arabia", abbr: " SAU" },
      { state: "Senegal", abbr: " SEN" },
      { state: "Serbia", abbr: " SRB" },
      { state: "Seychelles", abbr: " SYC" },
      { state: "Sierra Leone", abbr: " SLE" },
      { state: "Singapore", abbr: " SGP" },
      { state: "Slovakia", abbr: " SVK" },
      { state: "Slovenia", abbr: " SVN" },
      { state: "Solomon Islands", abbr: " SLB" },
      { state: "Somalia", abbr: " SOM" },
      { state: "South Africa", abbr: " ZAF" },
      { state: "South Georgia and the South Sandwich Islands", abbr: " SGS" },
      { state: "Spain", abbr: " ESP" },
      { state: "Sri Lanka", abbr: " LKA" },
      { state: "Sudan", abbr: " SDN" },
      { state: "Suriname", abbr: " SUR" },
      { state: "Svalbard and Jan Mayen", abbr: " SJM" },
      { state: "Swaziland", abbr: " SWZ" },
      { state: "Sweden", abbr: " SWE" },
      { state: "Switzerland", abbr: " CHE" },
      { state: "Syrian Arab Republic", abbr: " SYR" },
      { state: "Taiwan", abbr: " TW" },
      { state: "Taiwan", abbr: " TWN" },
      { state: "Tajikistan", abbr: " TJK" },
      { state: "Tanzania", abbr: " TZ" },
      { state: "Thailand", abbr: " THA" },
      { state: "Timor-Leste", abbr: " TLS" },
      { state: "Togo", abbr: " TGO" },
      { state: "Tokelau", abbr: " TKL" },
      { state: "Tonga", abbr: " TON" },
      { state: "Trinidad and Tobago", abbr: " TTO" },
      { state: "Trinidad & Tobago", abbr: " TTO" },
      { state: "Tunisia", abbr: " TUN" },
      { state: "Turkey", abbr: " TUR" },
      { state: "Turkmenistan", abbr: " TKM" },
      { state: "Turks and Caicos Islands", abbr: " TCA" },
      { state: "Tuvalu", abbr: " TUV" },
      { state: "Uganda", abbr: " UGA" },
      { state: "Ukraine", abbr: " UKR" },
      { state: "United Arab Emirates", abbr: " ARE" },
      { state: "United Kingdom", abbr: " GBR" },
      { state: "United States", abbr: " USA" },
      { state: "United States Minor Outlying Islands", abbr: " UMI" },
      { state: "Uruguay", abbr: " URY" },
      { state: "Uzbekistan", abbr: " UZB" },
      { state: "Vanuatu", abbr: " VUT" },
      { state: "Venezuela", abbr: " VE" },
      { state: "Venezuela", abbr: " VEN" },
      { state: "Viet Nam", abbr: " VNM" },
      { state: "Vietnam", abbr: " VNM" },
      { state: "Virgin Islands", abbr: " VG" },
      { state: "Virgin Islands", abbr: " VI" },
      { state: "Wallis and Futuna", abbr: " WLF" },
      { state: "Western Sahara", abbr: " ESH" },
      { state: "Yemen", abbr: " YEM" },
      { state: "Zambia", abbr: " ZMB" },
      { state: "Zimbabwe", abbr: " ZWE" },
    ],
    items_select_type_document: [
      { state: "CC - Cédula de ciudadanía", abbr: "CC" },
      { state: "CE - Cédula de Extranjeria", abbr: "CE" },
      { state: "OTRO, CUAL?", abbr: "OT" },
    ],
    items_select: [
      { state: "GOBERNACIÓN SAI", abbr: "GO" },
      { state: "AUNAP", abbr: "AU" },
      { state: "OTRO, CUAL?", abbr: "OT" },
    ],
    items_select_titular: [
      { state: "EMPRESA", abbr: "EM" },
      { state: "BUQUE(S)", abbr: "BU" },
      { state: "PROPIETARIO DEL BUQUE (S)", abbr: "PB" },
      { state: "OPERADOR / GERENTE DE BUQUES", abbr: "OG" },
      { state: "CAPITÁN DEL BARCO", abbr: "CB" },
    ],
    items_select_validity_permit: [
      { state: "1", abbr: "UNO" },
      { state: "2", abbr: "DOS" },
      { state: "3", abbr: "TRES" },
      { state: "4", abbr: "CUATRO" },
      { state: "5", abbr: "CINCO" },
    ],
    items_select_validity_permit_ind: [
      { state: "1", abbr: "UNO" },
      { state: "2", abbr: "DOS" },
      { state: "3", abbr: "TRES" },
    ],
    items_select_validity_permit_dep: [
      { state: "1", abbr: "UNO" },
      { state: "2", abbr: "DOS" },
    ],
    items_select_authorized_zone: [
      { state: "31. Atlantic, Western Central", abbr: "31" },
      { state: "77. Pacific, Eastern Central", abbr: "77" },
      { state: "87. Pacific, Southeast ", abbr: "87" },
      { state: "98. Other – Otro", abbr: "98" },
      { state: "99. Unknown - Desconocido", abbr: "99" },
      {
        state:
          "FAO Fishing Areas at Major Fishing Area, Subarea, Division or Subdivision level10, as documented here: http://www.fao.org/fishery/cwp/handbook/h/en",
        abbr: "FA",
      },
    ],
    items_select_types_fishing: [
      { state: "1. Langosta", abbr: "UNO" },
      { state: "2. Pesca blanca", abbr: "DOS" },
      { state: "3. Caracol", abbr: "TRES" },
      { state: "4. Multiespecífica", abbr: "CUATRO" },
      { state: "5. Otro, Cual?", abbr: "CINCO" },
    ],
    items_select_landing_port: [
      { state: "CP01. BUENAVENTURA", abbr: "UNO" },
      { state: "CP02. TUMACO", abbr: "DOS" },
      { state: "CP09. TOLU", abbr: "TRES" },
      { state: "CP05. CARTAGENA", abbr: "CUATRO" },
      { state: "CP03. BARRANQUILLA", abbr: "CINCO" },
      { state: "CP07. SAN ANDRES", abbr: "SEIS" },
      { state: "OTRO, CUAL?", abbr: "SIETE" },
    ],
    items_select_authorized_species: [
      { state: "ESPECIE 1", abbr: "UNO" },
      { state: "ESPECIE 2", abbr: "DOS" },
    ],
    items_select_fishing_arts: [
      { state: "1. Linea de mano", abbr: "UNO" },
      { state: "2. Nasas", abbr: "DOS" },
      { state: "3. Reel", abbr: "TRES" },
      { state: "4. Buceo", abbr: "CUATRO" },
    ],
    items_select_authorized_arts: [
      { state: "1. SURROUNDING NETS – Red de Cerco", abbr: "UNO" },
      { state: "2. SEINE NETS – Redes de tiro", abbr: "DOS" },
      { state: "3. TRAWLS – Redes de arrastre", abbr: "TRES" },
      { state: "4. DREDGES – RASTRAS", abbr: "CUATRO" },
      { state: "5. LIFT NETS – Redes izadas", abbr: "CINCO" },
      { state: "6. FALLING GEAR – Redes de caida", abbr: "SEIS" },
      {
        state: "7. GILLNETS AND ENTANGLING NETS- Red agallera y de enmalle",
        abbr: "SIETE",
      },
      { state: "8. TRAPS - Trampas", abbr: "OCHO" },
      { state: "9. HOOKS AND LINES- Líneas y anzuelos", abbr: "NUEVE" },
      {
        state: "10. GRAPPLING AND WOUNDING – artes de aferrar o herir",
        abbr: "DIEZ",
      },
      {
        state: "11. HARVESTING MACHINES - Máquinas de recolección",
        abbr: "ONCE",
      },
      {
        state: "20. MISCELLANEOUS GEAR - artes diversos - polivalente",
        abbr: "VEINTE",
      },
      {
        state: "25. RECREATIONAL FISHING GEAR – artes de uso recreativo",
        abbr: "VEINTICINCO",
      },
      { state: "6. FALLING GEAR – Redes de caida", abbr: "SEIS" },
      {
        state: "99. Unknown - Desconocido",
        abbr: "NOVENTAYNUEVE",
      },
      { state: "8. TRAPS - Trampas", abbr: "OCHO" },
    ],
  };
  return variables;
}
export default consultarVariables;
